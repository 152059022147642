<template>
  <div>
    <GridColumnMenuCheckboxFilter
      :column="column"
      :filter="filter"
      :filterable="filterable"
      :searchBox="false"
      :dataItems="actionsColumMenuDataItems"
      :expanded="true"
      @expandchange="expandChange"
      @closemenu="closeMenu"
      @filterchange="filterChange"
    />
  </div>
</template>

<script>
import { GridColumnMenuCheckboxFilter } from '@progress/kendo-vue-grid'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    GridColumnMenuCheckboxFilter
  },
  props: {
    column: Object,
    filter: Object,
    filterable: Boolean,
    uniqueData: {
      type: Boolean,
      default: true
    },
    gridData: Array
  },
  data() {
    return {
      actionsColumMenuDataItems: [
        { action: 'Unsubscribed' },
        { action: 'Rejected' },
        { action: 'Declined' },
        { action: 'Duplicate' }
      ]
    }
  },
  computed: {
    ...mapState('restrictedEntities', ['filteredColumnList']),
    oneVisibleColumn() {
      // Computed property that returns true if exactly one column is visible (not hidden)
      return this.currentColumns.filter((column) => !column.hidden).length === 1
    }
  },
  created() {
    // Initialize the currentColumns data property with the value of the columns prop
    this.$data.currentColumns = this.$props.columns
  },
  emits: [
    'columnssubmit',
    'closemenu',
    'expandchange',
    'filterchange',
    'sortchange'
  ],
  methods: {
    ...mapActions('restrictedEntities', ['addFilteredColumnList']),
    handleFocus(e) {
      this.$emit('contentfocus', e)
    },
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      let processedDescriptor = null
      let nestedFilters = null // Initialize nestedFilters to null

      if (newDescriptor && newDescriptor.filters.length > 0) {
        // Extract filters specific to `action`
        nestedFilters = newDescriptor.filters.find(
          (f) =>
            f.logic === 'or' &&
            Array.isArray(f.filters) &&
            f.filters.some((filter) => filter.field === 'action')
        )

        if (nestedFilters) {
          // Extract user selections for `action`
          const selectedValues = nestedFilters.filters
            .filter((f) => f.field === 'action')
            .map((f) => String(f.value))

          // Rebuild the filters correctly
          const newFilters = selectedValues.map((value) => ({
            field: 'action',
            operator: 'contains',
            value
          }))

          // Replace filters instead of resetting them
          nestedFilters.filters = newFilters

          // Wrap the updated filter in an "AND" group
          processedDescriptor = {
            logic: 'and',
            filters: [nestedFilters]
          }
        }
      }

      // Merge with the parent filter
      const parentFilter = this.$props.filter || { logic: 'and', filters: [] }

      const updatedFilters = parentFilter.filters.filter(
        (f) => !f.filters.some((filter) => filter.field === 'action')
      )

      if (processedDescriptor) {
        updatedFilters.push(...processedDescriptor.filters)
      }

      const finalDescriptor = {
        logic: 'and',
        filters: updatedFilters
      }

      // Determine if the filter for this column is applied
      const isFilterApplied = !!(
        nestedFilters && nestedFilters.filters.length > 0
      )

      // Highlight the column using the isFilterApplied flag
      this.addFilteredColumnList({
        columnName: 'action',
        isFilter: isFilterApplied
      })

      // Emit the final filter descriptor to the parent
      this.$emit('filterchange', finalDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    }
  }
}
</script>
