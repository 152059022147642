export const restrictedRecordsColumns = [
  {
    title: 'Entity Details',
    children: [
      {
        field: 'selected',
        width: '45px',
        columnMenu: false,
        groupable: false,
        headerSelectionValue: false
      },
      {
        field: 'sgaId',
        title: 'SGA ID',
        minWidth: 130,
        width: 150,
        minResizableWidth: 130,
        groupable: true,
        columnMenu: 'customFilterTemplate'
      },
      {
        field: 'requestId',
        title: 'Request ID',
        minWidth: 120,
        width: 150,
        minResizableWidth: 120,
        groupable: false,
        columnMenu: 'customFilterTemplate'
      },
      {
        field: 'legalEntityName',
        title: 'Legal Entity Name',
        minWidth: 250,
        width: 300,
        minResizableWidth: 250,
        groupable: false,
        cell: 'legalEntityTemplate',
        columnMenu: 'customFilterTemplate'
      },
      {
        field: 'countryDOMName',
        title: 'Country Of Domicile',
        columnMenu: 'customFilterTemplate',
        minWidth: 250,
        width: 250,
        minResizableWidth: 220,
        groupable: false
      },
      {
        field: 'immediateParentName',
        title: 'Immediate Parent Name',
        columnMenu: 'customFilterTemplate',
        groupable: true,
        minWidth: 300,
        width: 300,
        minResizableWidth: 300
      },
      {
        field: 'ultimateParentName',
        title: 'Ultimate Parent Name',
        columnMenu: 'customFilterTemplate',
        groupable: true,
        minWidth: 250,
        width: 300,
        minResizableWidth: 250
      },
      {
        field: 'lei',
        title: 'LEI',
        columnMenu: 'customFilterTemplate',
        groupable: false,
        minWidth: 200,
        width: 250,
        minResizableWidth: 150
      },
      {
        field: 'action',
        title: 'Action',
        minWidth: 200,
        width: 200,
        minResizableWidth: 200,
        columnMenu: 'actionCustomFilterTemplate',
        sortable: true,
        groupable: true
      }
    ]
  }
]
