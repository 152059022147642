<template>
  <div class="restricted-records-container">
    <div v-if="loading" class="local-loader-container">
      <div class="inava-loader"></div>
    </div>
    <div class="restricted-grid-toolbar">
      <div class="restricted-grid-toolbar-filters">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :value="searchWord"
            v-model="searchWord"
            :placeholder="'Search by Entity Name or SGA ID or Request ID'"
            :inputPrefix="'prefix'"
            @input="onFilter"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>

    <div class="restricted-entities-toolbar-container">
      <div class="restricted-entities-toolbar-row">
        <!-- Export Button -->
        <div class="restricted-entities-toolbar-column">
          <b-button
            class="action-button export-csv-button"
            size="is-small"
            :disabled="isDownloadDataLoading"
            :loading="isDownloadDataLoading"
            @click="exportCSVAndDownload"
          >
            <b-icon icon="file" size="is-small" class="toolbar-icons"> </b-icon>
            &nbsp; Export CSV
          </b-button>
        </div>
      </div>
    </div>
    <Grid
      ref="restricted-grid-table"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData.data"
      :resizable="true"
      :reorderable="true"
      :sortable="true"
      :pageable="gridPageable"
      :groupable="true"
      :group="group"
      :sort="sort"
      :filter="filter"
      :take="take"
      :skip="skip"
      :total="gridData.total"
      :expand-field="expandField"
      :loading="loading"
      :selectable="true"
      :selected-field="selectedField"
      :columns="updatedColumns"
      :column-menu="columnMenu"
      class="grid-table"
      @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange"
      @datastatechange="dataStateChange"
      @expandchange="expandChange"
    >
      <template v-slot:legalEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.isUpdated"
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
            <b-icon icon="flag" size="is-small"></b-icon>
          </div>
        </td>
        <td v-else class="k-table-td" role="gridcell" colspan="1">
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
          </div>
        </td>
      </template>
      <template v-slot:actionCustomFilterTemplate="{ props }">
        <actionColumnMenu
          :column="props.column"
          :columns="props.columns"
          :filterable="props.filterable"
          :filter="props.filter"
          :sortable="props.sortable"
          :sort="props.sort"
          :unique-data="false"
          :gridData="restrictedRecordItems"
          @sortchange="(e) => props.onSortchange(e)"
          @filterchange="handleGenericFilterChange"
          @closemenu="(e) => props.onClosemenu(e)"
          @contentfocus="(e) => props.onContentfocus(e)"
          @columnssubmit="onColumnsSubmit"
        />
      </template>
      <template v-slot:customFilterTemplate="{ props }">
        <defaultColumnMenu
          :column="props.column"
          :filterable="props.filterable"
          :filter="props.filter"
          :sortable="props.sortable"
          :sort="props.sort"
          @sortchange="(e) => props.onSortchange(e)"
          @filterchange="(e, f) => props.onFilterchange(e, f)"
          @closemenu="(e) => props.onClosemenu(e)"
          @contentfocus="(e) => props.onContentfocus(e)"
          @columnssubmit="onColumnsSubmit"
        />
      </template>
    </Grid>
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { restrictedRecordsColumns } from './restrictedRecordsData.js'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import ActionsColumnMenu from './ActionsColumnMenu.vue'
import RestrictedColumnMenu from './RestrictedColumnMenu.vue'
import { filterIcon } from '@progress/kendo-svg-icons'
import { downloadCSV } from '../../../../util/util.js'

export default {
  name: 'App',
  components: {
    Grid,
    'k-input': Input,
    actionColumnMenu: ActionsColumnMenu,
    defaultColumnMenu: RestrictedColumnMenu
  },
  provide() {
    return {
      kendoIcons: {
        type: 'svg',
        icons: {
          'more-vertical': filterIcon
        }
      }
    }
  },
  data() {
    return {
      searchWord: '',
      columns: restrictedRecordsColumns,
      columnMenu: true,
      selectedField: 'selected',
      expandField: 'expanded',
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      skip: 0,
      take: 10,
      group: [],
      sort: [],
      filter: null,
      total: this.totalRecords,
      isDownloadDataLoading: false
    }
  },
  computed: {
    ...mapState('restrictedEntities', [
      'isLoading',
      'restrictedRecords',
      'totalRecords',
      'filteredColumnList'
    ]),

    restrictedRecordItems() {
      return this.restrictedRecords || []
    },
    loading() {
      return this.isLoading
    },
    selectedDataItems() {
      return this.gridData?.data?.filter((item) => item.selected) || []
    },
    areAllSelected() {
      const tempGridData = this.gridData?.data || []
      const areAllSelected =
        tempGridData.length > 0 && tempGridData.every((item) => item.selected)
      return areAllSelected
    },
    updatedColumns() {
      return this.columns.map((column) => {
        if (column.children) {
          const updatedChildren = column.children.map((child) => {
            if (child.field === 'selected') {
              const updatedChild = {
                ...child,
                headerSelectionValue: this.areAllSelected
              }
              return updatedChild
            }
            return child
          })
          return { ...column, children: updatedChildren }
        }
        return column
      })
    }
  },
  async mounted() {
    this.clearFilterHighlights()
    await this.getRestrictedRecords()
    this.gridData = this.getData(this.restrictedRecords)
  },
  watch: {
    restrictedRecords: {
      handler(newValue) {
        this.gridData = this.getData(newValue)
      },
      immediate: true
    },
    filteredColumnList() {
      this.columns = this.getColumnWithFilteredApplied()
    }
  },
  methods: {
    ...mapActions('restrictedEntities', ['getRestrictedRecords']),
    onFilter(e) {
      this.searchWord = e.value
      this.take = 10
      this.skip = 0
      this.filter = this.searchWord
        ? {
            logic: 'or',
            filters: [
              'sgaId',
              'requestId',
              'legalEntityName',
              'countryDOMName',
              'immediateParentName',
              'ultimateParentName',
              'lei',
              'action'
            ].map((field) => ({
              field,
              operator: 'contains',
              value: this.searchWord
            }))
          }
        : null
      this.gridData = this.getData(this.restrictedRecordItems)
    },
    onHeaderSelectionChange(event) {
      const checked = event.event.target.checked

      this.gridData = {
        ...this.gridData,
        data: this.gridData.data.map((item) => ({
          ...item,
          selected: checked
        }))
      }
    },
    onSelectionChange(event) {
      this.gridData = {
        ...this.gridData,
        data: this.gridData.data.map((item) =>
          item === event.dataItem ? { ...item, selected: !item.selected } : item
        )
      }
    },
    getData(data) {
      return process(data, {
        take: this.take,
        skip: this.skip,
        group: this.group,
        sort: this.sort,
        filter: this.filter
      })
    },

    dataStateChange(event) {
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.filter = event.data.filter
      this.group = event.data.group

      this.gridData = this.getData(this.restrictedRecordItems)
    },

    expandChange(event) {
      if (event.dataItem) {
        event.dataItem[event.target.$props.expandField] = event.value
      }
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState
    },
    handleGenericFilterChange(newFilter) {
      this.skip = 0 // Reset the skip value
      this.filter = newFilter // Update the filter property
      this.gridData = this.getData(this.restrictedRecordItems)
    },
    getColumnWithFilteredApplied() {
      const allColumns = this.columns.map((groupCol) => {
        const children = groupCol.children.map((col) => {
          let filterIndicator = ''
          const index = this.filteredColumnList.indexOf(col.field)
          if (index > -1) {
            filterIndicator = 'filter-indicator'
          }
          return {
            ...col,
            headerClassName: filterIndicator
          }
        })
        return {
          ...groupCol,
          children: children
        }
      })
      console.log('allColumns', allColumns)
      return allColumns
    },
    clearFilterHighlights(columns = this.columns) {
      for (const column of columns) {
        // Clear highlighting
        if (column.headerClassName) {
          column.headerClassName = ''
        }
        // Recurse if children exist
        if (column.children && column.children.length > 0) {
          this.clearFilterHighlights(column.children)
        }
      }
    },
    exportCSVAndDownload() {
      // Determine which data to export:
      let dataForExport = []
      if (this.selectedDataItems.length > 0) {
        dataForExport = this.selectedDataItems
      } else {
        // Process the entire filtered dataset (ignoring pagination)
        dataForExport = process(this.restrictedRecordItems, {
          group: this.group,
          sort: this.sort,
          filter: this.filter
        }).data
      }

      if (!dataForExport.length) {
        Snackbar({ message: 'No Data to export.', type: 'is-danger' })
        return
      }

      // If your data is grouped and you have a method t flatten it, use it.
      if (
        this.group.length > 0 &&
        typeof this.flattenGroupedData === 'function'
      ) {
        dataForExport = this.flattenGroupedData(dataForExport)
      }

      // Dynamically build header rows (parent headers and column headers) from this.columns.
      // This works regardless of the group header title.
      const parentHeaders = []
      const columnHeaders = []
      const columnFields = []

      this.columns.forEach((column) => {
        // If this column has children (grouped header)
        if (column.children && column.children.length > 0) {
          // Push the parent's title once.
          parentHeaders.push(column.title)
          // Iterate through children.
          column.children.forEach((child, idx) => {
            if (child.field !== 'selected') {
              columnHeaders.push(child.title || child.field)
              columnFields.push(child.field)
              // Add an empty cell for parent's header if not the last child.
              if (idx !== column.children.length - 1) {
                parentHeaders.push('')
              }
            }
          })
        } else {
          // For a non-grouped column, if any.
          if (column.field !== 'selected') {
            parentHeaders.push(column.title || column.field)
            columnHeaders.push(column.title || column.field)
            columnFields.push(column.field)
          }
        }
      })

      // Build CSV header rows using semicolon (;) as delimiter.
      const headers = parentHeaders.join(';') + '\n' + columnHeaders.join(';')

      // Format the data rows based on the dynamic column fields.
      const csvData = dataForExport.map((item) =>
        columnFields
          .map((field) => {
            let cellValue = item[field]
            if (cellValue === undefined || cellValue === null) {
              cellValue = ''
            } else {
              cellValue = cellValue.toString()
              // Escape double quotes by doubling them.
              cellValue = cellValue.replace(/"/g, '""')
              // Wrap in quotes if the value contains a semicolon.
              if (cellValue.indexOf(';') !== -1) {
                cellValue = `"${cellValue}"`
              }
            }
            return cellValue
          })
          .join(';')
      )

      const fileName = `export_${moment().format('DD-MM-YYYY_HH:mm')}.csv`
      // Include a header for Excel to use semicolon as the delimiter.
      const csvContent = `sep=;\n${headers}\n${csvData.join('\n')}`

      // Trigger download using a helper function.
      downloadCSV(csvContent, fileName)
    }
  }
}
</script>

<style lang="scss" scoped>
.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  background: rgba(0, 0, 0, 0.1);
  z-index: 101;

  .inava-loader {
    position: relative;
    display: flex;
    margin: auto;
    border-radius: 50%;
    border: none;
    z-index: 900;
    width: 70px;
    height: 70px;
    background-image: '@/assets/Inava-logo-GIF.gif';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.restricted-records-container {
  margin-left: 27px;
  margin-right: 27px;
  padding-top: 27px;
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.restricted-records-container ::v-deep(.restricted-grid-toolbar) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  border: none;

  .restricted-grid-toolbar-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .k-grid-search {
      height: 36px;
      width: 100%;
      .k-input {
        border: 1px solid #cccccc;

        input {
          // height: 44px;
          font-size: 13px;
          line-height: 20px;
          font-weight: 600;
          font-family: Quicksand;
          font-style: normal;
        }

        .k-input-inner {
          margin-left: 0.1rem;
        }

        .k-input-prefix {
          margin-left: 0.5rem;
          color: #cccccc;
        }
      }
    }
  }
}

.restricted-records-container ::v-deep(.restricted-entities-toolbar-container) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;

  .restricted-entities-toolbar-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
  }

  .action-button {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    border-radius: 4px;
  }

  .toolbar-icons {
    width: 17px;
    height: 17px;
  }

  .toolbar-share-icon {
    transform: scaleX(-1);
  }

  .toolbar-icons-set-assignees {
    color: #00218a;
  }

  .export-csv-button {
    border: 1px solid #444444;
    color: #444444;

    &:hover {
      background: #444444;
      border: 1px solid #444444;
      color: #fff;
    }
  }
}

.restricted-records-container ::v-deep(.k-grid-header) {
  padding: 0px !important;
  width: 100%;

  .k-table-row:first-child {
    .k-table-th:first-child {
      background: #c4c8f8;
    }

    .k-table-th {
      background: #8d95f5;
      text-align: left;
      font-style: normal;
      font-variant: normal;
      font-weight: medium;
      font-size: 20px;
      line-height: 26px;
      font-family: Quicksand;

      letter-spacing: 0.24px;
      color: #ffffff;
      text-align: center;

      .k-cell-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
    }
  }

  .k-table-row {
    .k-table-th {
      background: #dfdfdf;
      font-size: 16px;
      color: #000000;
      line-height: 19px;
    }
  }

  .k-header > div > a {
    top: 4px;
  }

  .k-header.active > div > a {
    color: #fff;
    background-color: #ff6358;
    top: 4px;
  }
}

.restricted-records-container ::v-deep(.grid-table) {
  .k-grid-aria-root {
    .k-grid-container {
      .k-grid-content {
        overflow-y: hidden;
        .k-grid-table-wrap {
          .k-table {
            .k-table-tbody {
              .k-table-alt-row {
                background-color: none;
              }

              .k-selected {
                background-color: none;
              }

              .k-table-row {
                border: 1px solid black;

                .k-table-td {
                  font-style: normal;
                  font-variant: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;

                  letter-spacing: 0.16px;
                  color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.restricted-records-container ::v-deep(.grid-topbar) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 26px;
  border: none;
  background-color: var(--background-color);
}

.restricted-records-container ::v-deep(.k-grid-pager) {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .k-pager-info {
    display: flex;
    text-align: right;
    justify-content: flex-start;
  }
}

.restricted-records-container ::v-deep(.k-animation-container) {
  .k-list-container {
    .k-list {
      .k-list-content {
        .k-list-ul {
          .k-list-item.k-selected {
            background-color: #00218a;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .restricted-records-container ::v-deep(.k-grid-header) {
    .k-table-row:first-child {
      .k-table-th:first-child {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .k-table-row {
      .k-table-th {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  .restricted-records-container ::v-deep(.grid-table) {
    .k-grid-aria-root {
      .k-grid-container {
        .k-grid-content {
          .k-grid-table-wrap {
            .k-table {
              .k-table-tbody {
                .k-table-row {
                  .k-table-td {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .restricted-records-container ::v-deep(.k-grid-pager) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .k-pager-info {
      font-size: 16px;
    }

    .k-pager-sizes {
      font-size: 16px;
      .k-dropdownlist {
        font-size: 16px;
      }
    }
    .k-pager-numbers-wrap {
      font-size: 16px;
      .k-pager-numbers {
        button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}
</style>
