<template>
  <div>
    <GridColumnMenuFilter
      :expanded="true"
      :column="column"
      :filterable="filterable"
      :filter="filter"
      :filterOperators="filterOperators"
      @filterfocus="handleFocus"
      @closemenu="closeMenu"
      @expandchange="expandChange"
      @filterchange="filterChange"
    />
  </div>
</template>
<script>
import { GridColumnMenuFilter } from '@progress/kendo-vue-grid'
import { mapActions, mapState } from 'vuex/dist/vuex.common.js'

export default {
  components: {
    GridColumnMenuFilter: GridColumnMenuFilter
  },
  props: {
    column: Object,
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    filter: Object,
    filterable: Boolean,
    columns: Array
  },
  data() {
    return {
      currentColumns: [],
      columnsExpanded: false,
      filterExpanded: false,

      filterOperators: {
        text: [
          { text: 'grid.filterContainsOperator', operator: 'contains' },
          {
            text: 'grid.filterNotContainsOperator',
            operator: 'doesnotcontain'
          },
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
          { text: 'grid.filterEndsWithOperator', operator: 'endswith' }
        ],
        numeric: [
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterGteOperator', operator: 'gte' },
          { text: 'grid.filterGtOperator', operator: 'gt' },
          { text: 'grid.filterLteOperator', operator: 'lte' },
          { text: 'grid.filterLtOperator', operator: 'lt' }
        ],
        date: [
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
          { text: 'grid.filterAfterOperator', operator: 'gt' },
          { text: 'grid.filterBeforeOperator', operator: 'lt' },
          { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
        ],
        boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
      }
    }
  },
  computed: {
    ...mapState('restrictedEntities', ['filteredColumnList']),
    oneVisibleColumn() {
      // Computed property that returns true if exactly one column is visible (not hidden)
      return this.currentColumns.filter((column) => !column.hidden).length === 1
    }
  },
  created() {
    this.$data.currentColumns = this.$props.columns
  },
  emits: [
    'columnssubmit',
    'closemenu',
    'expandchange',
    'filterchange',
    'sortchange'
  ],
  methods: {
    ...mapActions('restrictedEntities', ['addFilteredColumnList']),
    handleFocus(e) {
      this.$emit('contentfocus', e)
    },
    onToggleColumn(id) {
      this.currentColumns = this.currentColumns.map((column, idx) => {
        return idx === id ? { ...column, hidden: !column.hidden } : column
      })
    },
    onReset(event) {
      event.preventDefault()
      const allColumns = this.$props.columns.map((col) => {
        return {
          ...col,
          hidden: false
        }
      })

      this.currentColumns = allColumns
      this.onSubmit()
    },
    onSubmit(event) {
      if (event) {
        event.preventDefault()
      }
      this.$emit('columnssubmit', this.currentColumns)
      this.$emit('closemenu')
    },
    onMenuItemClick() {
      const value = !this.columnsExpanded
      this.columnsExpanded = value
      this.filterExpanded = value ? false : this.filterExpanded
    },
    onFilterExpandChange(value) {
      this.filterExpanded = value
      this.columnsExpanded = value ? false : this.columnsExpanded
    },
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      // Determine if a filter is active based on the provided newDescriptor
      let isFilter = !!newDescriptor
      // Compare the number of filters in newDescriptor with the filteredColumnList length
      if (
        newDescriptor &&
        newDescriptor.filters.length !== this.filteredColumnList.length
      ) {
        // Check if the field exists in the filteredColumnList; if it does, consider it not a new filter
        const index = this.filteredColumnList.indexOf(e.field)
        isFilter = !(index > -1) // if found in the list set to false
      } else if (
        newDescriptor &&
        newDescriptor.filters.length === this.filteredColumnList.length &&
        e.event.type === 'reset'
      ) {
        // In case of a reset event with matching filter counts, check if the field is in the list to flag it as active
        const index = this.filteredColumnList.indexOf(e.field)
        isFilter = index > -1
      }
      this.addFilteredColumnList({
        columnName: e.field,
        isFilter: isFilter
      })
      this.$emit('filterchange', newDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    }
  }
}
</script>
